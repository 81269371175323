.info-box {
  color: $white-alt;
  background-color: $dark-alt;
  @each $name, $color in $theme-colors-alt {
    .info-box {
      .bg-#{$name} {
        color: color-contrast($color);

        .progress-bar {
          background-color: color-contrast($color);
        }
      }
    }
  }
}
