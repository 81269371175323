// Base styles

// NOT NEEDED
// .alert {
//   border: $alert-border-width solid transparent;
// }

// Headings for larger alerts
.alert-heading {
  color: inherit;
}

// Generate contextual modifier classes for colorizing the alert.

@each $state, $value in $theme-colors-alt {
  $alert-background-alt: shift-color($value, $alert-bg-scale-alt);
  $alert-border-alt: shift-color($value, $alert-border-scale-alt);
  $alert-color-alt: shift-color($value, $alert-color-scale-alt);
  @if (contrast-ratio($alert-background-alt, $alert-color-alt) < $min-contrast-ratio-alt) {
    $alert-color-alt: mix($value, color-contrast($alert-background-alt, $color-contrast-dark-alt, $color-contrast-light-alt, $min-contrast-ratio-alt), abs($alert-color-scale-alt));
  }
  .alert-#{$state} {
    @include alert-variant($alert-background-alt, $alert-border-alt, $alert-color-alt);
  }
}
