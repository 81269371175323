// Color variants
@each $name, $color in $theme-colors-alt {
  @include cards-variant($name, $color);
}

@each $name, $color in $colors-alt {
  @include cards-variant($name, $color);
}

.card {
  .card {
    background-color: tint-colors($gray-900-alt, 5%);
  }
  .nav.flex-column > li {
    border-bottom-color: $gray-600-alt;
  }
  .card-footer {
    background-color: rgba($black-alt, .1);
  }
  &.card-outline-tabs .card-header a:hover {
    border-color: $gray-600-alt;
    border-bottom-color: transparent;
  }
  &:not(.card-outline) > .card-header a.active {
    color: $white-alt;
  }
}

.card-comments {
  background-color: tint-colors($gray-900-alt, 1.25%);
  .username {
    color: $gray-400-alt;
  }
  .card-comment {
    border-bottom-color: tint-colors($gray-900-alt, 7.5%);
  }
}

.todo-list > li {
  color: $white-alt;
  background-color: tint-colors($gray-900-alt, 5%);
  border-color: tint-colors($gray-900-alt, 7.5%);
}

.todo-list {
  @each $name, $color in $theme-colors-alt {
    .#{$name} {
      border-left-color: $color;
    }
  }

  @each $name, $color in $colors-alt {
    .#{$name} {
      border-left-color: $color;
    }
  }
}
