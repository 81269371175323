.form-floating {

  // > label {
  //   border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
  // }

  > .form-control {
    &::placeholder {
      color: transparent;
    }
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: $form-floating-label-opacity-alt;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity-alt;
    }
  }
}
