.breadcrumb {
  background-color: $breadcrumb-bg-alt;
}

.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {

    &::before {
      color: $breadcrumb-divider-color-alt;
    }
  }

  &.active {
    color: $breadcrumb-active-color-alt;
  }
}
