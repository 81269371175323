// Dynamic Variables
// --------------------------------------------------------

:root {
  // Sidebar active links
  --lte-sidebar-menu-active-bg: #{$primary-alt};
  --lte-sidebar-menu-active-color: #{$white-alt};

  // Dark Sidebar Native Variables
  --lte-sidebar-dark-bg: #{$dark-alt};

  // Light Sidebar Native Variables
  --lte-sidebar-light-bg: #{$white-alt};
}

// MAIN FOOTER
// --------------------------------------------------------
$lte-main-footer-bg-alt:          $dark-alt !default;

// Body background (Affects main content background only)
$lte-main-bg-alt:                 $gray-900-alt !default;
$lte-main-color-alt:              $white-alt !default;
