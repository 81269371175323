// xxxstylelint-disable declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix


// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css ... (see core `_reboot.scss` file)
// Normalize is licensed MIT. https://github.com/necolas/normalize.css


// Body

body {
  color: var(--#{$variable-prefix}body-color-alt);
  background-color: var(--#{$variable-prefix}body-bg-alt); // 2
}


// Content grouping

hr {
  color: $hr-color-alt; // 1
  // background-color: currentcolor;
  opacity: $hr-opacity-alt;
}


// Typography

%heading {
  color: $headings-color-alt;
}


// Mark

mark {
  background-color: $mark-bg-alt;
}


// Links

a {
  color: $link-color-alt;

  &:hover {
    color: $link-hover-color-alt;
  }
}

a:not([href]):not([class]) {
  &,
  &:hover {
    color: inherit;
  }
}


// Code

pre {
  color: $pre-color-alt;

  // Account for some code outputs that place code tags in pre tags
  code {
    color: inherit;
  }
}

code {
  color: $code-color-alt;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    color: inherit;
  }
}

kbd {
  color: $kbd-color-alt;
  background-color: $kbd-bg-alt;
}


// Tables
//
// Prevent double borders

caption {
  color: $table-caption-color-alt;
}
