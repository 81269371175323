// Actual modal
.modal-content {
  color: $modal-content-color-alt;
  background-color: $modal-content-bg-alt;
  border: $modal-content-border-width solid $modal-content-border-color-alt;
  @include box-shadow($modal-content-box-shadow-xs-alt);
}

// Modal background
.modal-backdrop {
  @include overlay-backdrop-alt($modal-backdrop-bg-alt, $modal-backdrop-opacity-alt);
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  border-bottom: $modal-header-border-width solid $modal-header-border-color-alt;
}

// Footer (for actions)
.modal-footer {
  border-top: $modal-footer-border-width solid $modal-footer-border-color-alt;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up-alt);
  }
}
