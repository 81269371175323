//
// Basic Bootstrap table
//

.table {
  --#{$variable-prefix}table-bg: #{$table-bg-alt};
  --#{$variable-prefix}table-accent-bg: #{$table-accent-bg-alt};
  --#{$variable-prefix}table-striped-color: #{$table-striped-color-alt};
  --#{$variable-prefix}table-striped-bg: #{$table-striped-bg-alt};
  --#{$variable-prefix}table-active-color: #{$table-active-color-alt};
  --#{$variable-prefix}table-active-bg: #{$table-active-bg-alt};
  --#{$variable-prefix}table-hover-color: #{$table-hover-color-alt};
  --#{$variable-prefix}table-hover-bg: #{$table-hover-bg-alt};

  color: $table-color-alt;
  border-color: $table-border-color-alt;

  // DONT NEED TO DARK, CSS VARS TAKES CARE OF THIS.
  // Target th & td
  // xstylelint-disable-next-line selector-max-universal
  // > :not(caption) > * > * {
  //   background-color: var(--#{$variable-prefix}table-bg);
  //   box-shadow: inset 0 0 0 9999px var(--#{$variable-prefix}table-accent-bg);
  // }

  // Highlight border color between thead, tbody and tfoot.
  > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-group-separator-color-alt;
  }
}


// Zebra-striping

// DONT NEED TO DARK, CSS VARS TAKES CARE OF THIS.
// .table-striped {
//   > tbody > tr:nth-of-type(#{$table-striped-order}) {
//     --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
//     color: var(--#{$variable-prefix}table-striped-color);
//   }
// }

// Active table

// DONT NEED TO DARK, CSS VARS TAKES CARE OF THIS.
// .table-active {
//   --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-active-bg);
//   color: var(--#{$variable-prefix}table-active-color);
// }

// Hover effect

// DONT NEED TO DARK, CSS VARS TAKES CARE OF THIS.
// .table-hover {
//   > tbody > tr:hover {
//     --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-hover-bg);
//     color: var(--#{$variable-prefix}table-hover-color);
//   }
// }


// Table variants

@each $color, $value in $table-variants-alt {
  @include table-variant-alt($color, $value);
}
